import React, { useState } from "react";
import Modal from "react-modal";
import "./style.scss";

export const CheckSubscriptionModal = ({ isOpenModel, title, bodyTitle, buttonCloseHandler, buttonSubmitHandler, cancelText, submitText }) => {
  const [isValidEmail,setIsValidEmail] = useState(true);
  const [email, setEmail] = useState();
  const cancel = () => {
    buttonCloseHandler();
  }
  const submit = () => {
    emailAction(email);
  }

  const emailAction = (email) => {
    setEmail(email);
    if (!email) {
      setIsValidEmail(false)
    }
   else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsValidEmail(false)
    }
    else {
      setIsValidEmail(true);
      buttonSubmitHandler(email);
    }
  };
 
  return (

    <div className="modalComponent-root">
      <Modal
       ariaHideApp={false}
        isOpen={isOpenModel}
        onRequestClose={cancel}
        className="check-subscription-modal"
        overlayClassName="discard-overlay"
        contentLabel="Example Modal">

        <div className="check-subscription-modal">
          <div className="check-subscription-modal-header">
            <div className="check-subscription-modal-title-wrapper">
              <span className="check-subscription-modal-title">{title}</span>
            </div>
            <div className="modal-block-image">
              <img className="icon" alt="close" src="./icons/close.svg" onClick={cancel} />
            </div>
          </div>

          <div className="check-subscription-modal-description-wrapper">
            <span className="check-subscription-modal-description-title">{bodyTitle}</span>
            <input
              onBlur={(e) => emailAction(e.currentTarget.value)}
              className= {isValidEmail? "check-subscription-modal-input" : "check-subscription-modal-input-error"}
              type="email"
            />
          </div>

          <div className="check-subscription-modal-bottom-block">
            <button onClick={submit} type="danger" className="check-subscription-modal-button">{submitText}</button>
            <button onClick={cancel} type="danger" className="check-subscription-modal-button">{cancelText}</button>
          </div>
          
        </div>

      </Modal>
    </div>

  );
};
