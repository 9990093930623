import React from 'react';
import "./style.scss";
import { settings } from "../../settings";
import translations from "../Common/translations.json";


export const ErrorPage = () => {
    const translation = translations[settings.lang];

    return (
        <div className="unsubscribepage-wrapper">
            <div className="unsubscribepage-detail-wrapper">
                <div className="subscription-detail-block-top">
                    <span className="subscription-detail-title">
                        {translation.modalStrings.noSubscribtion}
                    </span>
                </div>
                <div className="subscription-detail-block-bottom">
                    <span className="subscription-detail-title">
                        {translation.modalStrings.unsubscribed}
                    </span>
                </div>
            </div>
        </div>
    )
}