import { settings } from "../../../../../settings";
import { http } from "../../../../Common/api";
import { toast } from "react-toastify";
import { ToastInfo } from "../../toast";
export const UseSubscribeEmail = (
  email,
  categories,
  UUID,
  listingId,
  isNeedListing,
  companyId,
  lang,
  translations
) => {
  const requestObj = {
    categories: categories,
    companyId: companyId,
    email: email,
    language: settings.lang === "en" ? "ENGLISH" : "ARABIC",
    listingSubscriptions: isNeedListing,
  };
  const subscibeEmail = async () => {
    await http
      .post(`subscription`, requestObj)
      .then((response) => response.data)
      .catch((e) => {
        console.log("Error: subscibeEmail");
      });

    toast(
      <ToastInfo
        content={`${translations.toast.confirmationMessage} ${translations.toast.pleaseAcceptTheRequest}`}
        title={translations.toast.youWereSuccessfully}
      />,
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  };
  subscibeEmail();
};
