import React, { useState } from "react";
import { CommunicationPreferences } from "./components/CommunicationPreference";
import { TitleBlock } from "./components/TitlesBlock";
import "./style.scss";
import "antd/dist/antd.css";
import { UseGetCategories } from "./hooks/useGetCategories";
import { ShareAlertPreferences } from "./components/ShareAlertPreferences";
import { PersonalDetail } from "./components/PersonalDetail";
import { ButtonsBlock } from "./components/ButtonsBlock";
import { UseGetSubscription } from "./hooks/UseGetSubscription";
import translations from "../Common/translations.json";
import { settings } from "../../settings";
import { ModalNewSubscription } from "./components/ModalNewSubscription";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spin } from "antd";

export const MainPage = ({styles, isAppReady}) => {
  const [categoriesList, setCategoriesList] = useState([]);

  UseGetCategories(setCategoriesList);

  const subscription = useSelector((state) => state.mainPage.subscription);
  const companyName =
    settings.lang === "en"
      ? subscription.companyNames.ENGLISH
      : subscription.companyNames.ARABIC;

  const translation = translations[settings.lang];
  const [modalNewsSubscriptionIsOpen, setModalSudscribeIsOpen] = useState(
    false
  );

  const modalClose = () => {
    setModalSudscribeIsOpen(false);
  };

  const getActivationHeader = () => {
    if (settings.activate === "true") {
      return (
        <div className="main-company-subscription-confirmed-block">
          <span className="main-company-subscription-confirmed-title">
            {translation.modalStrings.subscriptionConfirmed}
          </span>
        </div>
      );
    }
  };

  const getFontFamily = () => {
    return {
      fontFamily:
        settings.lang === "ar"
          ? "Hanifonts"
          : styles &&
            styles.fontFamily &&
            styles.fontFamily[settings.lang],
    };
  };
  const fonts = styles.fonts || {};
  const backgroundColors = styles.backgroundColors || {};
  return (
    <div className="main-wrapper" style={getFontFamily()}>
      {!isAppReady ? (
        <div className="spiner-wrapper">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className="main-company-title-block">
            <span className="main-company-title" style={{
                color: fonts.body
              }}>{companyName}</span>
          </div>
          {getActivationHeader()}

          <div className="subscription-detail-wrapper">
            <div className="subscription-detail-block">
              <span className="subscription-detail-title" style={{
                color: fonts.body
              }}>
                {translation.titles.subscriptionDetailTitle}
              </span>
            </div>
            <div className="communication-preferences-block">
              <TitleBlock
                title={translation.titles.communicationPreferencesTitle}
                backgroundColors={backgroundColors}
                fonts={fonts}
              />
              <CommunicationPreferences
                categoriesList={categoriesList}
                translation={translation.communicationPreferences}
                language={subscription.language}
                fonts={fonts}
              />
            </div>
            <div className="share-alert-preferences-block">
              <TitleBlock
                title={translation.titles.shareAlertPreferencesTitle}
                backgroundColors={backgroundColors}
                fonts={fonts}
              />
              <ShareAlertPreferences
                translation={translation.shareAlertPreferences}
                subscription={subscription}
                fonts={fonts}
              />
            </div>
            <div className="personal-detail-block">
              <TitleBlock
                title={translation.titles.personalDetailTitle}
                backgroundColors={backgroundColors}
                fonts={fonts}
              />
              <PersonalDetail
                translation={translation.personalDetail}
                setModalSudscribeIsOpen={setModalSudscribeIsOpen}
                emailSubscription={subscription.email}
                fonts={fonts}
              />
              <ButtonsBlock
                translation={translation.buttonsBlock}
                toastTranslation={translation.toast}
                fonts={fonts}
              />
            </div>
            <ModalNewSubscription
              isOpenModel={modalNewsSubscriptionIsOpen}
              buttonCloseHandler={modalClose}
            />
          </div>
          <ToastContainer autoClose={5000} hideProgressBar={true} />
        </>
      )}
    </div>
  );
};
