import React from "react";
import "./style.scss";

export const EmailBlock = ({
  fonts,
  translations,
  setEmail,
  isValidEmail,
  checkValidation
}) => {  
  const setEmailAction = (email) => {    
    setEmail(email);
  }
  const checkEmailAction = (email) => {    
    checkValidation(email);
  }

  return (
    <div className="emailBlock-root">
      <span >{translations.modalStrings.subscribeToReceiveCompany}</span>
      <div className="emailBlock-input-title">
        <span> {translations.personalDetail.email} </span>
        <span className="emailBlock-input-title-star">*</span>
      </div>
      <input
        onChange={(e) => setEmailAction(e.currentTarget.value)}
        onBlur={(e) => checkEmailAction(e.currentTarget.value)}
        className= {isValidEmail? "emailBlock-input-modal" : "emailBlock-input-modal-error"}
        type="email"
      />
    </div>
  );
};
