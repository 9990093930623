import React from "react";
import "./style.scss";

export const TitleBlock = ({ title, backgroundColors = {}, fonts = {} }) => {
  return (
    <div
      className="title-block-wrapper"
      style={{
        backgroundColor: backgroundColors.tableHeader,
      }}
    >
      <span
        className="title-block-text"
        style={{
          color: fonts.tableHeader
            ? fonts.tableHeader
            : backgroundColors.tableHeader
            ? "#ffffff"
            : null,
        }}
      >
        {title}
      </span>
    </div>
  );
};
