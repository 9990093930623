import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import { appReducer } from "./reducers";
import { watchAppSaga } from "./sagas";

const sagaMiddleWare = createSagaMiddleware();

export const store = createStore(
    appReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleWare))
)

sagaMiddleWare.run(watchAppSaga);