import { takeEvery, select } from "redux-saga/effects";
import { UPDATE_SUBSCRIPTION_REQUEST } from "./consts";
import { http } from "../Common/api/index";
import { toast } from "react-toastify";
import { ToastInfo } from "../MainPage/components/toast";

export function* mainPageSaga() {
  yield takeEvery(UPDATE_SUBSCRIPTION_REQUEST, (payload) =>
    updateSubscroptionRequest(payload)
  );
}

function* updateSubscroptionRequest({ payload }) {
  const obj = yield select((state) => state.mainPage.subscription);
  console.log(payload)
  try {
    yield http.put(`subscription`, obj).then((response) => response.data);
    toast(<ToastInfo content={payload.toastTranslation.updates} fonts={payload.fonts}/>, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } catch (e) {
    console.log("Error : " + e);
  }
}
