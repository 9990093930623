import React from "react";
import Modal from "react-modal";
import "./style.scss";

export const ModalComponent = ({ isOpenModel, title, bodyTitle, body, buttonHandler, buttonText }) => {
  const cancel = () => {
     buttonHandler();
  }

 // var stringWithBrs = body.split('\n').map(str => <p key={str} className="discard-modal-description-body-p">{str}</p>);
  return (
    <div className="modalComponent-root">
      <Modal
        ariaHideApp={false}
        isOpen={isOpenModel}
        onRequestClose={cancel}
        className="discard-modal"
        overlayClassName="discard-overlay"
        contentLabel="Example Modal">
        <div className="discard-modal">
          <div className="discard-modal-header">
            <div className="discard-modal-title-wrapper">
              <span className="discard-modal-title">{title}</span>
            </div>
            <div className="modal-block-image">
              <img className="icon" alt="close" src="./icons/close.svg" onClick={cancel} />
            </div>
          </div>
          <div className="discard-modal-description-wrapper">
            <span className="discard-modal-description-title">{bodyTitle}</span>
            <span className="discard-modal-description-body">{"stringWithBrs"}</span>
          </div>
          <div className="discard-modal-bottom-block">
            <button onClick={cancel} type="danger" className="discard-modal-button-cancel">{buttonText}</button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
