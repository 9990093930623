import React from "react";
import "./style.scss";


export const ToastInfo = ({ title, content, isShowIcon, fonts }) => {
    return (
        <div className="toast_root">
            {
                isShowIcon && <img className="toast_logo" src="/assets/icons/autonomotech-avatar-onDark.png" alt="icon" />
            }
            <div className="toast_info_block">
                {title && <span className="toast_info_top">{title}</span>}
                {content && <div className="toast_info_bottom">{content}</div>}
            </div>
        </div>
    )
}