import { SET_SUBSCRIPTION, SET_CATEGORIES, SET_LANGUAGE, SET_LISTING_SUBSCRIPTION, SET_PRICE_TARGET, SET_STOP_LOSS, SET_VOLUME_TARGET, SET_PRICE_CHANGE,UPDATE_SUBSCRIPTION_REQUEST } from './consts';

export const setSubscription = (payload) => ({
    type: SET_SUBSCRIPTION,
    payload,
});

export const setCategories = (payload) => ({
    type: SET_CATEGORIES,
    payload,
});

export const setLanguage = (payload) => ({
    type: SET_LANGUAGE,
    payload,
});

export const setListingSubscription = (payload) => ({
    type: SET_LISTING_SUBSCRIPTION,
    payload,
});


export const setPriceTarget = (payload) => ({
    type: SET_PRICE_TARGET,
    payload,
});

export const setStopLoss = (payload) => ({
    type: SET_STOP_LOSS,
    payload,
});

export const setVolumeTarget = (payload) => ({
    type: SET_VOLUME_TARGET,
    payload,
});

export const setPriceChange = (payload) => ({
    type: SET_PRICE_CHANGE,
    payload,
});

export const updateSubscriptionRequest = (payload) => ({
    type: UPDATE_SUBSCRIPTION_REQUEST,
    payload,
})

