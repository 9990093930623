import React from "react";
import { MainPage } from "../MainPage";
import { ErrorPage } from "../ErrorPage";
import { settings } from "../../settings";
import { Layout } from "antd";
import { Route, Router } from "react-router-dom";
import { UnsubscribePage } from "../UnsubscribePage";
import { UseGetSubscription } from "../MainPage/hooks/UseGetSubscription";

export const AppRouter = () => {
  const [styles = {}, isAppReady = false] = UseGetSubscription();

  return (
    <Layout>
      <Router history={settings.history}>
        <Route path={["/"]} exact component={() => <MainPage styles={styles} isAppReady={isAppReady} />} />
        <Route path={["/*/unsubscribe", "/unsubscribe"]} component={() => <UnsubscribePage />} />
        <Route path={["/*/error-page","/error-page"]} component={() => <ErrorPage />} />
      </Router>
    </Layout>
  );
};
