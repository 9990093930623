import React from "react";
import Modal from "react-modal";
import { EmailSubscription } from "../EmailSubscription";
import translations  from "../../../Common/translations";
import "./style.scss";
import { settings } from "../../../../settings";

export const ModalNewSubscription = ({ isOpenModel, buttonCloseHandler }) => {
  const cancel = () => {
    buttonCloseHandler();
  }

  const translation = translations[settings.lang];
  return (
    <div className="modalComponent-root">
      <Modal
        ariaHideApp={false}
        isOpen={isOpenModel}
        onRequestClose={cancel}
        className="modal-subscription"
        overlayClassName="modal-subscriptio-overlay"
        contentLabel="Example Modal">
        <div className="modal-subscription-wrapper">
          <div className="modal-subscription-header">
            <div className="modal-subscription-title-wrapper">
              <span className="modal-subscription-title">{"New Subscription"}</span>
            </div>
            <div className="modal-block-image">
              <img className="icon" alt="close" src="./icons/close.svg" onClick={cancel} />
            </div>
          </div>
          <div className="modal-block-email-subscription">
          <EmailSubscription 
          translations={translation}/>
          </div>

          {/* <div className="modal-subscription-description-wrapper">
        
          </div> */}
          {/* <div className="modal-subscription-bottom-block">
            <button onClick={cancel} type="danger" className="modal-subscription-button-cancel">{"buttonText"}</button>
          </div> */}
        </div>
      </Modal>
    </div>
  );
};
