import React, { useState } from "react";
import { CheckboxBlock } from "./components/CheckboxBlock";
import { EmailBlock } from "./components/EmailBlock";
import { SubscribeBlock } from "./components/SubscribeBlock";
import { UseSubscribeEmail } from "./hooks/useSubscribeEmail";
import "./style.scss";
import { settings } from "../../../../settings";
import { UseGetCategories } from "../../hooks/useGetCategories";
import { useSelector } from "react-redux";

export const EmailSubscription = ({
  lang,
  configuration = {},
  backgroundColors,
  fonts,
  translations,
  barColors,
  lineColors,
  fontFamily,
}) => {
  const [email, setEmail] = useState();
  const [categories, setCategories] = useState();
  const { listingId, UUID } = settings;
  const [isNeedListing, setListing] = useState(true);
  const [categoriesList, setCategoriesList] = useState([]);
  const [isNotReady, setIsNotReady] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [modalSudscribeIsOpen, setModalSudscribeIsOpen] = useState(false);
  const companyId = useSelector(
    (state) => state.mainPage.subscription.companyId
  );

  //  UseGetCategories(setCategoriesList, setIsNotReady);
  UseGetCategories(setCategoriesList);
  const subscribeAction = () => {
    let state = checkValidation(email);
    if (state) {
      UseSubscribeEmail(
        email,
        categories,
        UUID,
        listingId,
        isNeedListing,
        companyId,
        lang,
        translations
      );
      //setModalSudscribeIsOpen(true);
    }
  };
  function checkValidation(email) {
    if (!email) {
      setIsValidEmail(false);
      return false;
    } else if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setIsValidEmail(false);
      return false;
    } else {
      setIsValidEmail(true);
      return true;
    }
  }
  return isNotReady ? (
    <div>loader...</div>
  ) : (
    <div className="emailSubscription-root">
      <EmailBlock
        translations={translations}
        fonts={fonts}
        setEmail={setEmail}
        isValidEmail={isValidEmail}
        checkValidation={checkValidation}
      />
      <div className="emailSubscription-checkboxBlock">
        <CheckboxBlock
          translations={translations}
          setCategories={setCategories}
          setListing={setListing}
          categoriesList={categoriesList}
        />
      </div>
      <div className="emailSubscription-note">
        <span
          className="emailSubscription-span"
        >
          {translations.modalStrings.youMayManageAll}
        </span>
      </div>
      <div className="emailSubscription-subscribeBlock">
        <SubscribeBlock
          translations={translations}
          fonts={fonts}
          configuration={configuration}
          subscribeAction={subscribeAction}
          modalSudscribeIsOpen={modalSudscribeIsOpen}
          setModalSudscribeIsOpen={setModalSudscribeIsOpen}
        />
      </div>
    </div>
  );
};
