export const subscriptionDefault = {
    "id": null,
    "email": null,
    "companyNames":{"ENGLISH": null,"ARABIC": null},
    "language":"ENGLISH",
    "categories":[],
    "listingSubscriptions":[
      {"id":"",
      "listingId":"",
      "dailyClose":false,
      "priceTarget":null,
      "stopLoss":null,
      "volumeTarget":null,
      "priceChange":null}
    ]}