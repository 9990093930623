import { MAIN_PAGE, SET_SUBSCRIPTION, SET_CATEGORIES, SET_LANGUAGE, SET_LISTING_SUBSCRIPTION, SET_PRICE_CHANGE, SET_PRICE_TARGET } from "./consts";
import { subscriptionDefault } from './DefaultValue/subscriptionDefault'

export const initState = {
     isAppReady: "not ready",
     subscription: subscriptionDefault,
}

export const mainPageReducer = (state = initState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case MAIN_PAGE:
        return {
          ...state,
          isAppReady: payload
        };

      case SET_SUBSCRIPTION:
        return {
          ...state,
          subscription: payload
        };  

      case SET_CATEGORIES:
        return{
          ...state,
          subscription: {...state.subscription, categories: payload}
      };  

      case SET_LANGUAGE:
       
        return{
          ...state,
          subscription: {...state.subscription, language: payload}
      }; 

      case SET_LISTING_SUBSCRIPTION:
        return{
          ...state,
          subscription:  {...state.subscription, listingSubscriptions: payload}
      };  
  
      default:
        return state;
    }
}

