import { useEffect} from "react";
import { http } from "../../Common/api";

export const UseGetCategories = (setCategoriesList) => {  
    useEffect(() => {
        const getCategories = async () => {
            const data = await http
              .get('subscription/categories')
              .then((response) => response.data)   
              .catch((e) => {
                console.log("Error: getCategories");
              });      
              
              setCategoriesList(data);           
          }
          getCategories();
      }, [setCategoriesList]);
    
}