import { APP_SET_APP_READY } from "./consts";

export const initState = {
     isAppReady: "not ready",
}

export const globalReducer = (state = initState, action) => {
    const { type, payload } = action;
  
    switch (type) {
      case APP_SET_APP_READY:
        return {
          ...state,
          isAppReady: payload
        };
  
      default:
        return state;
    }
}