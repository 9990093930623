import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { settings } from "../../../settings";
import { http } from "../../Common/api";
import { setSubscription } from "../actions";
import { subscriptionDefault } from "../DefaultValue/subscriptionDefault";

export const UseGetSubscription = () => {
  const dispatch = useDispatch();
  const [styles, setStyle] = useState({});
  const [isAppReady, setAppReady] = useState(false);

  useEffect(() => {
    const getSubscription = async () => {
      
      const dataSubscription = await http
        .get(`subscription/${settings.listing}`)
        .then((response) => response.data)
        .catch((e) => {
          settings.history.push({ pathname: "/error-page", search: `?lang=${settings.lang}` });                   
        });

      if (dataSubscription) {
        document.title = `Subscription Preferences - ${dataSubscription.companyNames.ENGLISH}`;
        dispatch(setSubscription(dataSubscription));
      } else {
        dispatch(setSubscription(subscriptionDefault));
      }

      if (
        dataSubscription &&
        dataSubscription.id &&
        settings.activate === "true"
      ) {
        await http
          .put(`subscription/${dataSubscription.id}/activate`)
          .then((response) => response.data)
          .catch((e) => {
            console.log("Error: activate");
          });
      } 

      if (dataSubscription && dataSubscription.companyId) {
        try {
          const id = dataSubscription.companyId;
          const dataStyles = await http
            .getStyles(id)
            .then((response) => response.data)
            .catch((e) => {
              console.log("Error: dataStyles");
            });

          setStyle(dataStyles.configuration.global);
          setAppReady(true);
        } catch (e) {
          setAppReady(true);
        }
      } else {
        setAppReady(true);
      }
    };
    if (settings.listing) {
      getSubscription();
    } else {
      settings.history.push({ pathname: "/error-page", search: `?lang=${settings.lang}` });                   
    }
  }, [dispatch]);
  return [styles, isAppReady];
};
