export const MAIN_PAGE = "MAIN_PAGE";
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_LISTING_SUBSCRIPTION = "SET_LISTING_SUBSCRIPTION";
export const SET_PRICE_TARGET = "SET_PRICE_TARGET";
export const SET_STOP_LOSS = "SET_STOP_LOSS";
export const SET_VOLUME_TARGET = "SET_VOLUME_TARGET";
export const SET_PRICE_CHANGE = "SET_PRICE_CHANGE";
export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST";

